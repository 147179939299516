.inner-wrap .heading {
    padding: 35px 0;
    padding-bottom: 21px;
  }
  .pagination{
    justify-content: end;
    margin-top: 20px;
  }

  /* ul.pagination li:last-child {
    display: none;
  } */
  /* ---- */
ul.pagination li:last-child::after{
  content: "";
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-left: 10px;
    cursor: pointer;
}
ul.pagination li:last-child a{
  display: none;
}
ul.pagination li:nth-last-child(2) a{
 display: none;
}
/* ========= */
ul.pagination li a {
  text-decoration: none;
  padding: 0 9px;
  color: #000;
  font-size: 17px;
}
ul.pagination li {
  padding: 3px;
}
ul.pagination li:nth-child(2) a{
  display: none;
}
ul.pagination li:nth-child(7) a{
  display: none;
}
ul.pagination li:nth-child(7)::after {
  content: "";
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-left: 10px;
  cursor: pointer;
}
ul.pagination   li:nth-child(2)::after {
  content: "";
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-right: 10px;
  cursor: pointer;
}
ul.pagination .active {
  background-color: #f6992d;
  border-radius: 21px;
  padding: 3px;
}
ul.pagination li:first-child {
    display: none;
}
  @media (max-width: 425px) {
    .inner-wrap .heading {
      flex-direction: column;
      align-items: center;
    }
  }
  .inner-wrap .heading p {
    color: #002a4a;
    font-weight: 500;
    font-size: 18px;
  }
  .inner-wrap .heading p a {
    color: #000;
    text-decoration: none;
  }
  .inner-wrap .heading p a:hover {
    color: #000;
  }