.profile {
    margin-top: 5rem;
  }
  .profile .wrap {
    border-radius: 5px;
    box-shadow: 8px 4px 19px 0px #00000011;
    padding: 3rem;
  }
  .profile .wrap button {
    width: 100%;
    padding: 11px 20px !important;
    border-radius: 35px !important;
    background-color: #ff992b;
    border-color: #ff992b;
    box-shadow: 0px 3px 12px rgba(249, 161, 27, 0.382321);
    margin-top: 19px;
  }
  .profile .wrap button:focus {
    box-shadow: unset;
  }
  .profile .wrap .form label {
    color: #002a4a;
  }
  .profile .wrap .form input {
    border: 0;
    background-color: #fafafa;
    padding: 10px 9px;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
 form .username {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 36px;
    display: flex;
    padding: 4px 18px;
    align-items: center;
}
form .username input{
  box-shadow: unset;
}
form .username input:focus {
  box-shadow: unset;
  background-color: transparent !important;
}
#profile_lastname input:focus{
  background-color: transparent !important;
}
#profile_lastname {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  padding: 4px 18px;
  border-radius: 36px;  
}  
#profile_lastname input:focus {
  box-shadow: unset !important;
}
.user_img {
  position: relative; 
  max-width: 105px;
  width: 100%;
  margin: 0 auto;
}
.edit_userimg{
    position: absolute;
    right: 0;
    bottom: -4%;
}
.user_img img {
  object-fit: cover;
  border: 1px solid #ccccccb5;
}
.dropdown img {
  object-fit: cover;
  width: 35px;
  height: 35px;
  border: 1px solid #ccccccde;
}