img {
    max-width: 100%;
}

.new_home {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #204f790a;
    background-image: url("../../assets/images/shape1.svg")
}

.newhome_box {
    border: 1px solid #d3d3d36e;
    text-align: center;
    border-radius: 13px;
    background-color: #fff;
    margin-bottom: 50px;
    transition: .3s ease;
}

.newhome_box:hover {
    box-shadow: 0 6px 22px 6px #204f7929;
    border-color: transparent;
    transition: .3s ease;
}

.newhome_box .newhome_box_head {
    background-color: #204f79;
    padding: 20px;
    width: 90%;
    margin: auto;
    border-radius: 10px;
    margin-top: -31px;
}

.newhome_box .newhome_box_head h2 {
    color: #fff;
    font-size: 26px;
    font-weight: 600;
}

.newhome_box h4 {
    color: #000;
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 40px;
}

.newhome_box p {
    color: #383838;
    font-size: 19px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 40px;
    margin-left: 40px;
    margin-right: 40px;
}

.newhome_box_content {
    padding: 40px 20px;
}

.btn.view_btn1 {
    background-color: #204f79;
    padding: 10px 40px;
    letter-spacing: 1px;
    border-radius: 6px;
    transition: .3s ease;
}

.btn.view_btn1:hover {
    background-color: #1e5f9a !important;
    transform: translateY(-5px);
    transition: .3s ease;
}