.findings {
    background-color: #fff;
    box-shadow: 8px 4px 19px 0px #00000011;
    color: #002a4a;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .findings .inner-cover {
    padding: 2rem;
    border-right: 1px dashed #dddddd;
    margin: 4px;
    padding-top: 0;
  }
  .highlight {
    border: 1px solid #FF992B !important;
    background-color: #FFEAD5;
  }
/* 18 apr 2022 */
.common_nav{
  position: relative;
  margin-top: 11px;
}
.drop-down-select{
  display: block;
}
.diagnosis input {
  padding-right: 13px !important;
}
.cat.highlight{
  position: relative;
}
.common_nav small {
  position: absolute;
  top: -11px;
  right: -9px;
  z-index: 1;
}
.loc{
  margin-right: 45px;
}
.drop-down-select {
  display: block;
  background-color: #fff;
  max-width: 170px;
  width: 100%;
}
.findings .types .cat{
  margin-bottom: 0;
}
.common_nav {
  position: relative;
  max-width: 170px;
  width: 100%;
}
.drop-down-select p{
  margin-bottom: 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  max-width: 400px;
  width: 100%;
  padding: 7px 30px 7px 13px;
  font-size: 14px;
}
.cat small {
  position: absolute;
  top: -11px;
  z-index: 999;
  right: -7px;
}
.findings .types .cat {
  position: relative;
}
.w-85.d-flex {
  flex-wrap: wrap;
}
.findings .types .common_nav .cat{
  font-size: 14px;
  padding: 7px 6px;
}
.cat.highlight small {
  position: absolute;
  top: -12px;
  right: -9px;
}
.next span{
  font-weight: 500;
}
.diagnosis .form-select {
  background-image: unset;
}
.diagnosis small {
  position: absolute;
  top: 21px;
  right: -10px;
}
.diagnosis {
  position: relative;
}
.serach-result div {
  margin-bottom: 4px;
}

.serach-result {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
  height: 150px;
    overflow-y: scroll;
}
.serach-result div {
  margin-bottom: 4px;
}
.findings.row h5 {
    padding: 10px 36px;
    margin-bottom: 0;
}

  @media (max-width: 991px) {
    .findings .inner-cover {
      border-right: unset;
      padding-bottom: 0;
    }
  }
  @media (max-width: 425px) {
    .findings .inner-cover {
      padding-left: 14px;
      padding-right: 14px;
      padding-top: 14px;
    }
  }
  .findings h5 {
    text-transform: uppercase;
  }
  .findings .types {
    margin-bottom: 20px;
    align-items: baseline;
  }
  @media (max-width: 425px) {
    .findings .types {
      flex-direction: column;
    }
  }
  @media (max-width: 425px) {
    .findings .types .w-25 {
      width: 100% !important;
    }
  }
  .findings .types strong {
    text-transform: uppercase;
    font-size: 14px;
  }
  @media (max-width: 600px) {
    .findings .types strong {
      font-size: 13px;
    }
  }
  .findings .types .w-75 {
    justify-content: end;
    flex-wrap: wrap;
  }
  @media (max-width: 425px) {
    .findings .types .w-75 {
      justify-content: flex-start;
      width: 100% !important;
    }
  }
  .findings .types .cat {
    padding: 7px 14px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 14px;
  }
  .findings .types .cat span {
    color: #002a4a;
    font-size: 14px;
  }
  @media (max-width: 600px) {
    .findings .types .cat span {
      font-size: 12px;
    }
  }
  .findings .right-cover {
    padding: 2rem;
    padding-top: 0;
  }
  @media (max-width: 991px) {
    .findings .right-cover {
      padding-top: 0px;
    }
  }
  @media (max-width: 425px) {
    .findings .right-cover {
      padding-left: 14px;
      padding-right: 14px;
      padding-top: 14px;
      padding-bottom: 0px;
    }
  }
  .findings .right-cover h5 {
    visibility: hidden;
  }
  @media (max-width: 991px) {
    .findings .right-cover h5 {
      display: none;
    }
  }
  
  .location {
    padding: 2rem;
  }
  @media (max-width: 425px) {
    .location {
      padding-left: 14px;
      padding-right: 14px;
      padding-top: 14px;
    }
  }
  
  .impression {
    color: #002a4a;
    margin-top: 3rem;
  }
  @media (max-width: 568px) {
    .impression {
      padding: 0 13px;
    }
  }
  .impression h5 {
    text-transform: uppercase;
    margin-bottom: 12px;
  }
  .impression p {
    font-weight: 500;
    margin-bottom: 10px;
  }
  @media (max-width: 991px) {
    .impression p {
      margin-top: 13px;
    }
  }
  @media (max-width: 600px) {
    .impression p {
      font-size: 14px;
    }
  }
  .impression select {
    font-size: 14px;
  }
  
  .next button {
    padding: 11px 20px !important;
    border-radius: 35px !important;
    background-color: #ff992b;
    border-color: #ff992b;
    box-shadow: 0px 3px 12px rgba(249, 161, 27, 0.382321);
    width: 135px;
  }
  .next button:focus {
    box-shadow: unset;
  }
  .next button:hover {
    background-color: #ff992b;
    color: #fff;
    border-color: #ff992b;
  }
  .next button:focus {
    box-shadow: unset;
    background-color: #ff992b;
    color: #fff;
    border-color: #ff992b;
  }
  .next button:active {
    box-shadow: unset;
  }