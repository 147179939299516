
.case_collection_section {
    padding: 30px 0;
}

.case_collection_section h1 {
    font-size: 25px;
    margin-bottom: 20px;
}

.case_collection_section li {
    list-style: none;
}

.case_collection_section .accordion-button:not(.collapsed) {
    background-color: #204f79;
    color: #fff;
}

.case_collection_section .accordion-button:not(.collapsed)::after {
    filter: brightness(0) invert(1);
}


.case_collection_section .accordion-button:focus {
    box-shadow: unset;
}

.case_collection_section .accordion-button {
    font-size: 21px;
    color: #000;
}

.case_collection_section h3 {
    font-size: 16px;
    color: #000;
    margin-bottom: 15px;
}

.case_collection_section li a {
    color: #204f79;
    display: block;
    font-size: 15px;
    margin: 6px 0;
}

.case_collection_section .accordion-body {
    background-color: #204f790a;
}

.accordion-body .col-md-3:first-child .disease_list_box:before {
    content: unset;
}

.disease_list_box {
    padding: 12px 10px 15px 20px;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #d3d3d347;
    height: 200px;
    overflow: auto
}

.case_collection_section .accordion-item {
    border: 1px dotted rgba(0, 0, 0, .125);
}

.accordion__section:first-of-type .accordion {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}
.accordion__section:first-of-type{
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.accordion__section:last-of-type{
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}