@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  border: 1px dotted rgba(0, 0, 0, .125);
}
.accordion.active, .accordion.active:focus{
    background-color: #204f79;
    color: #fff;
}
/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  color: #444;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

.active {
  background-color: #204f79;
}

/* Style the accordion content title */
.accordion__title {
    font-size: 21px;
    color: #000;
    margin-bottom: 0;
}
.accordion.active .accordion__title{
    font-size: 21px;
    color: #fff;
}
/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
    background-color: #204f790a;
    padding: 20px;
}
.accordion span svg{
    transition: .3s ease;
    width: 18px;
    height: 18px;
}
.accordion.active span svg{
    transform: rotate(180deg);
    transition: .3s ease;
}
/* .accordion:hover span svg path{
    fill: #fff;
} */
.accordion.active span svg path{
    fill: #fff;
}